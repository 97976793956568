import React from 'react';
import PropTypes from 'prop-types';
import { useLocalCart } from 'contexts/cart';
import { CartCard } from 'components/store/daffodil/CartCard';
import { Button } from 'phoenix-components';
import { useLanguageLoader } from 'contexts/language';
import { useRouter } from 'next/router';
import { useIsCustomDomain } from 'contexts/profile';
import { emitEvent,EVENT_TYPES } from 'ui/lib/pixels';
import styles from './Cart.module.css';

export function Cart({ shop }) {

  const { products = [], breakup } = useLocalCart();
  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();
  const lang = useLanguageLoader();

  if (products.length === 0) {

    return null;
  
  }

  return (
    <div className={styles.container}>
      <div className={styles.borderLeft}>
        <div className={styles.cart}>Cart</div>
        <div className={styles.items}>
          {products.length}
          {' '}
          {lang('store.words.item')}
          {products.length > 1 ? 'S' : ''}
        </div>
        <div>
          {products.map(product => (
            <CartCard shop={shop} product={product} key={product._id} />
          ))}
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.subTotal}>
          <div>
            {lang('store.words.subTotal')}
          </div>
          <div>
            {shop.currency}
            {' '}
            {breakup.total}
          </div>
        </div>
        <div className={styles.extraCharges}>
          {lang('store.headings.extraChargesMayApply')}
        </div>
      </div>
      <div className={styles.button}>
        <Button
          onClick={() => {
            emitEvent(EVENT_TYPES.PRODUCT_CHECKOUT)
            router.push({
              pathname: isCustomDomain ? '/cart' : `/${shop?.slug}/cart`,
              query: 'stepper=true',
            });
          
          }}
          label={lang('buttons.checkout')}
          primary
          fullWidth
        />
      </div>
    </div>
  );

}

Cart.propTypes = {
  shop: PropTypes.object.isRequired,
};

Cart.defaultProps = {};
