import { defaultProps, props } from 'containers/themes/props/store';
import { TopBar } from 'components/store/TopBar';
import { getUserIcon } from 'components/utils/icon';
import { Grid } from '@mui/material';
import StoreDescription from 'components/store/StoreDescription';
import FollowUsAndMore from 'components/shop/FollowUsAndMore';
import React, { useEffect, useRef, useState } from 'react';
import { useDeviceWidth } from 'hooks/removeJss';
import Catalogs from 'components/store/daffodil/Catalogs';
import Templates from 'components/store/daffodil/Templates';
import ViewAll from 'components/store/common/ViewAll';
import { useLanguageLoader } from 'contexts/language';
import { ProductCard } from 'components/store/daffodil/ProductCard';
import { useIsDesktop } from 'contexts/profile';
import NoOrders from 'components/home/NoOrders';
import { useInfiniteShopProducts } from 'hooks/product';
import { BorderBottom } from 'components/layout/BorderBottom';
import { Cart } from 'components/store/daffodil/Cart';
import { NavBar } from 'components/store/daffodil/NavBar';
import { Container } from 'components/layout/Container';
import styles from './store.module.css';
import ViewAllCatalogLazy from 'components/store/common/ViewAllCatalogLazy';
import ShopUtils from 'ui/lib/shopUtils';
import DisablePopup from 'components/store/common/DisablePopup';


const emptyProducts = '/images/store/noProducts.svg'

function DaffodilStore({
  shop,
  savedFeed,
  templates,
  catalogList,
}) {
  const width = useDeviceWidth();
  const [top, setTop] = useState(100);
  const [products] = useInfiniteShopProducts(savedFeed);
  const shopIcon = getUserIcon(shop);
  const lang = useLanguageLoader();
  const isDesktop = useIsDesktop();
  const ref = useRef();
  const showTemplate = ShopUtils.showBanner(shop);
  const shopProfileEnabled = ShopUtils.isSectionEnabled(shop, 'shopProfile');
  const collectionsEnabled = ShopUtils.isSectionEnabled(shop, 'collections');
  const featuredProductsEnabled = ShopUtils.isSectionEnabled(shop, 'featuredProducts');
  const feedTitle = ShopUtils.getTitle(shop, 'featuredProducts');
  const savedTitle = lang('store.headings.featuredProducts') === feedTitle ?
    lang('store.headings.featuredProducts') : feedTitle;

  useEffect(() => {
    setTop(ref?.current?.getBoundingClientRect()?.bottom - document?.body?.getBoundingClientRect().top);
  }, [ref?.current]);

  const props = [...new Array(6)]
    .map(() => ({ variant: 'large' }));

  return (
    <div>
      <DisablePopup blocked={shop?.blocked} />
      <TopBar shop={shop} catalogs={catalogList} showTabs={!isDesktop}/>
      <div ref={ref} id="shopScroll1"/>
      {shopProfileEnabled && (<>
        <div className="desktop">
          <div className={styles.shopIcon}>
            <Container className={styles.main}>
              <img className={styles.icon} src={shopIcon} alt=""/>
              <Grid container spacing={2} className={styles.shopIconGrid}>
                <Grid item xs={12} className={styles.shopName}>{shop?.name}</Grid>
                <Grid item xs={12}>
                  <StoreDescription shop={shop}/>
                </Grid>
                <Grid item xs={12}>
                  <FollowUsAndMore shop={shop} variant="minimal"/>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </>)}
      <div id="shopScroll2"/>
      <>
        {showTemplate && <Templates shop={shop} width={width} templates={templates}/>}
      </>
      {shopProfileEnabled && (
        <>
          <div className="mobile">
            <div className={styles.shopIcon}>
              <img className={styles.icon} src={shopIcon} alt=""/>
              <div>
                <div className={styles.shopName}>{shop?.name}</div>
                <StoreDescription shop={shop}/>
              </div>
            </div>
          </div>
        </>
      )}
      {collectionsEnabled && (
        <>
          <Catalogs shop={shop} catalogs={catalogList} width={width}/>
        </>
      )}
      <div id="shopScroll3"/>
      <BorderBottom/>
      <div className={styles.body}>
        {isDesktop && (
          <div
            className={styles.left}
            style={{ top: `${top || 100}px` }}
          >
            <NavBar catalogs={catalogList} shop={shop}/>
          </div>
        )}
        <div className={styles.center}>
          {products.length === 0 && (
            <div className={styles.overflow1}>
              <NoOrders
                title="Shop does not have any products yet!"
                icon={emptyProducts}
              />
            </div>
          )}
          {featuredProductsEnabled && (
            <ViewAll
              shop={shop}
              products={savedFeed}
              type="featured"
              name={savedTitle}
              label={`${lang('common.browse')} ${savedTitle}`}
            >
              {savedFeed.slice(0, 6)
                .map((product) => (
                  <ProductCard key={product._id} shop={shop} product={product} variant="large"/>
                ))}
            </ViewAll>
          )}
          <div id="shopCollections"/>
          {catalogList.map((catalog, idx) => (
            <>
              <div id={`shopCollection${idx}`}/>
              <ViewAllCatalogLazy
                height={isDesktop ? 800 : 600}
                key={catalog.value}
                shop={shop}
                name={catalog.value}
                component={ProductCard}
                size={6}
                props={props}
              />
            </>
          ))}
        </div>
        {isDesktop && (
          <div
            className={styles.right}
            style={{ top: `${top || 100}px` }}
          >
            <Cart shop={shop}/>
          </div>
        )}
      </div>
      {isDesktop && <BorderBottom/>}
    </div>
  );
}

DaffodilStore.propTypes = props;
DaffodilStore.defaultProps = defaultProps;

export default DaffodilStore;
