import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import { useLanguageLoader } from 'contexts/language';
import cx from 'classnames';
import _ from 'ui/lodash';
import styles from './NavBar.module.css';
import ShopUtils from "../../../ui/lib/shopUtils";

export function NavBar({
  shop,
  catalogs
}) {

  const hasCollections = !!catalogs.length;
  const [selected, setSelected] = useState('shop');
  const lang = useLanguageLoader();
  const regex = /(<([^>]+)>)/ig;
  const isFilledAboutUs = !!shop?.about?.replace(regex, '').length > 0;
  const isFilledTerms = shop?.tncs?.length > 0;
  const handleScroll = _.debounce(() => {
    const elements = ['shopScroll3'];
    if (hasCollections) {
      catalogs.forEach((c, idx) => {
        elements.push(`shopCollection${idx}`);
      });
    }
    const tops = elements.map(id => {
      const ele = document.getElementById(id);
      if (ele) {
        return ele.getBoundingClientRect().top - 100;
      }
      return 0;
    });
    setSelected(elements[tops.filter(x => x < 0).length - 1]);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const feedTitle = ShopUtils.getTitle(shop, 'collections');
  const savedTitle = lang('store.headings.collections') === feedTitle ?
    lang('store.headings.collections') : feedTitle;

  const feedTitleTime = ShopUtils.getTitle(shop, 'shopTimings');
  const savedTitleTime = feedTitleTime === "Shop Timings" ? lang('store.menu.storeTimings') : feedTitleTime;

  const feedTitlePolicies = ShopUtils.getTitle(shop, 'shopPolicies');
  const savedTitlePolicies = feedTitlePolicies === 'Shop Policies' ?
    lang('store.menu.termsPolicies') : feedTitlePolicies;

  const feedTitleAbout = ShopUtils.getTitle(shop, 'aboutUs');
  const savedTitleAbout = lang('store.menu.aboutUs') === feedTitleAbout ?
    lang('store.menu.aboutUs') : feedTitleAbout;


  const feedTitlePayment = ShopUtils.getTitle(shop, 'paymentMethods');
  const savedTitlePayment = lang('store.menu.paymentMethod') === feedTitlePayment ?
    lang('store.menu.paymentMethod') : feedTitlePayment;

  const feedTitleInfo = ShopUtils.getTitle(shop, 'storeInformation');
  const savedTitleInfo = lang('store.headings.storeInformation') === feedTitleInfo ?
    lang('store.headings.storeInformation') : feedTitleInfo;

  const feedFeaturedTitle = ShopUtils.getTitle(shop, 'featuredProducts');
  const savedFeaturedTitle = lang('store.headings.featuredProducts') === feedFeaturedTitle ?
    lang('store.headings.featuredProducts') : feedFeaturedTitle;
  const items = [];
  if (isFilledAboutUs) {
    items.push({
      label: savedTitleAbout,
      value: 'shopScroll4'
    });
  }
  if (isFilledTerms) {
    items.push({
      label: savedTitlePolicies,
      value: 'shopScroll5'
    });
  }
  items.push(
    {
      label: savedTitleTime,
      value: 'shopScroll6'
    }
  );
  items.push(
    {
      label: savedTitlePayment,
      value: 'shopScroll7'
    }
  );
  items.push(
    {
      label: lang('store.menu.contactUs'),
      value: 'shopScroll8'
    }
  );
  items.push(
    {
      label: savedTitleInfo,
      value: 'shopScroll9'
    }
  );

  const goto = (id) => () => {
    const ele = document.getElementById(id);
    if (!ele) {
      return;
    }
    const offset = 70;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = ele.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    setSelected(id);
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };


  return (
    <div className={styles.container}>
      <div>
        <Clickable
          className={cx(styles.shop, { [styles.selected]: selected === 'shopScroll1' })}
          onClick={goto('shopScroll1')}
        >
          {lang('store.menu.shop')}
        </Clickable>
        <Clickable
          onClick={goto('shopScroll3')}
          className={cx(styles.collections, { [styles.selected]: selected === 'shopScroll3' })}
        >
          {savedFeaturedTitle}
        </Clickable>
        {hasCollections
        && (
          <>
            <Clickable
              onClick={goto('shopCollections')}
              className={cx(styles.collections, { [styles.selected]: selected === 'shopCollections' })}
            >
              {savedTitle}
            </Clickable>
            {catalogs.map((c, idx) => (
              <div key={idx}>
                <Clickable
                  onClick={goto(`shopCollection${idx}`)}
                  className={cx(
                    styles.collection,
                    { [styles.selected]: selected === `shopCollection${idx}` },
                    'max20Ch'
                  )}
                >
                  {c.label}
                </Clickable>
              </div>
            ))}
          </>
        )}
        {items.map(item => (
          <div key={item.value}>
            <Clickable
              className={cx(styles.collections, { [styles.selected]: selected === item.value })}
              onClick={goto(item.value)}
            >
              {item.label}
            </Clickable>
          </div>
        ))}
      </div>
    </div>
  );

}

NavBar.propTypes = {
  catalogs: PropTypes.array,
  shop: PropTypes.object.isRequired,
};

NavBar.defaultProps = {
  catalogs: []
};
