import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import AddButton from 'components/store/common/AddButton';
import { getDiscount, getProductImageNull } from 'components/store/utils';
import { Grid } from '@mui/material';
import ProductColors from 'components/store/common/ProductColors';
import { useRouter } from 'next/router';
import { useIsCustomDomain, useIsDesktop } from 'contexts/profile';
import { useLanguageLoader } from 'contexts/language';
import ProductTags from 'components/store/common/ProductTags';
import styles from './ProductCard.module.css';
import GetPrice from '../common/GetPrice';

export function ProductCard({
  shop,
  product,
  variant,
}) {

  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();
  const isDesktop = useIsDesktop();

  const lang = useLanguageLoader();
  const isOOS = product?.status === 'out of stock';

  const img = getProductImageNull(product);

  const discount = getDiscount(product);

  const onClick = (_, value) => {
    let url = isCustomDomain ? `/${product.slug}` : `/${product.shopSlug}/${product.slug}`;
    if(!window.isWebView) {
      if (value) {
        url = url + `?color=${value}`;
      }
      window.open(url,'_blank');
    }
    else
      router.push(url);
  };

  return (
    <Grid item xs={12} md={variant === 'large' ? 12 : 6}>
      <div className={styles.main}>
        <div className={styles.container}>
          <Clickable className={styles.click} onClick={onClick}>
            <div className={styles.name}>
              {product.title}
            </div>
            <ProductColors onClick={onClick} product={product} />
            <div className={styles.price}>
              <div className={styles.amount}>
                {shop.currency}
                {' '}
                <GetPrice price={product.discountedAmount} />
              </div>
              {discount > 0 && (
                <>
                  <div className={styles.originalAmount}>
                    {shop.currency}
                    {' '}
                    {product.amount}
                  </div>
                  <div className={styles.discount}>
                    {discount}
                    % OFF
                  </div>
                </>
              )}
            </div>
            <div className={styles.unit}>
              {/* Per*/}
              {/* {' '}*/}
              {product.unit.value === 1 ? '' : product.unit.value}
              {' '}
              {product.unit.type}
            </div>
            <ProductTags product={product} shop={shop} />
          </Clickable>
          <div className={styles.right}>
            {img && (
              <Clickable onClick={onClick} className={styles.imgContainer}>
                <img loading={"lazy"} className={styles.img} src={img} alt="" />
                {isOOS && (
                  <span className={styles.oos}>{lang('buttons.soldOut1')}</span>
                )}
              </Clickable>
            )}
            {(!img || !isDesktop) && <AddButton shop={shop} product={product} />}
          </div>
        </div>
        {(img && isDesktop) && (
          <div className={styles.flexRight}>
            <AddButton shop={shop} product={product} />
          </div>
        )}
      </div>
    </Grid>
  );

}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

ProductCard.defaultProps = {
  variant: 'small'
};

export default ProductCard;
