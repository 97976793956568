import PropTypes from 'prop-types';
import React from 'react';
import { getDiscount } from 'components/store/utils';
import { Clickable } from 'phoenix-components';
import { MinusIcon, PlusIcon } from 'components/shared/Icons/theme';
import Loader from 'ui/services/loader';
import { addToCart, deleteCartItem, updateQuantity } from 'ui/api/cart';
import SnackBar from 'ui/services/snackbar';
import { useRefreshLocalCart } from 'contexts/cart';
import styles from './Cart.module.css';
import { emitEvent, EVENT_TYPES } from 'ui/lib/pixels';

export function CartCard({ shop, product }) {

  const discount = getDiscount(product);
  const refresh = useRefreshLocalCart();

  const onAdd = async () => {

    try {

      Loader.show();
      await addToCart(shop._id, {
        color: product?.color?.hex || null,
        note: '',
        quantity: 1,
        variantId: product.variantId,
      });
      emitEvent(EVENT_TYPES.ADD_TO_CART);
      refresh();
    
    } catch (e) {

      SnackBar.showError(e);
    
    } finally {

      Loader.hide();
    
    }
  
  };

  const onDelete = async () => {

    try {

      Loader.show();
      if (product.quantity > 1) {

        await updateQuantity(shop._id, product._id, {
          quantity: product.quantity - 1,
        });
      
      } else {

        await deleteCartItem(shop._id, product._id);
      
      }
      refresh();
    
    } catch (e) {

      SnackBar.showError(e);
    
    } finally {

      Loader.hide();
    
    }
  
  };

  return (
    <div className={styles.product}>
      <div className={styles.productLeft}>
        <div className={styles.title}>{product.title}</div>
        <div className={styles.pricing}>
          <div className={styles.amount}>
            {shop.currency}
            {' '}
            {product.discountedAmount}
          </div>
          {discount > 0 && (
            <>
              <div className={styles.originalAmount}>
                {shop.currency}
                {' '}
                {product.amount}
              </div>
              <div className={styles.discount}>
                {discount}
                % OFF
              </div>
            </>
          )}
        </div>
        <div className={styles.unit}>
          Per Piece
        </div>
      </div>
      <div>
        <div className={styles.addButton}>
          <div className={styles.add}>
            <Clickable onClick={onDelete} className={styles.minus}>
              <MinusIcon />
            </Clickable>
            <span>{product.quantity}</span>
            <Clickable onClick={onAdd}>
              <PlusIcon />
            </Clickable>
          </div>
        </div>
      </div>
    </div>
  );

}

CartCard.propTypes = {
  shop: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};
